body {
  background-color: #fbfcfb;
}


.h3 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 10px;
  max-width: 1280px;
  margin: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_logo {
  cursor: pointer;
  width: 150px;
  border-radius: 100%;
}

.content {
  flex: 1;
}

.title {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
}

.subtitle {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  margin: 1rem;
}

.text {
  font-size: 14px;
}

.main_image {
  border-radius: 30px;
  width: 50%;
}

.main_first_block {
  display: flex;
  gap: 30px;
  align-items: center;

  font-weight: 100;
}

.main_first_block_text {
  box-shadow: 4px 4px 23px 5px rgba(34, 60, 80, 0.2);
  border-radius: 16px;
  padding: 20px;
}

.main_second_block {
  display: flex;
  gap: 70px;
  align-items: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.footer__logo {
  flex: 1;
}

.footer__logo {
  width: 100px;
  border-radius: 100%;
}

.footer__info {
  flex: 2;
  text-align: right;
}

.footer__info p {
  margin: 5px 0;
  font-size: 0.9rem;
}

.snowflake {
  position: fixed;
  top: -10px;
  color: #7b8cee;
  font-size: 1em;
  opacity: 0.7;
  pointer-events: none;
  animation: fall linear infinite;
}

@keyframes fall {
  to {
    transform: translate(20vw, 100vh) rotate(360deg);
  }
}

.block {
  box-shadow: 4px 4px 23px 5px rgba(34, 60, 80, 0.2);
  border-radius: 16px;
  padding: 20px;
width: 100%;
}

.section {
  margin-top: 100px;
}

.listing {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 10px;
}

.listing_top {
  display: flex;
  gap: 30px;
}

.main-page {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.main-banner {
  position: relative;
  text-align: center;
  margin-bottom: 2rem;
}

.banner-image {
  width: 100%;
  height: auto;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-title {
  font-size: 2.5rem;
  color: #fff;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.card-container {
  display: flex;
  justify-content: space-around;
  gap: 1.5rem;
}

.card {
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 30%;
}

.card-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem;
  border-radius: 30px;
}

.card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.card p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.main_block_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search {
  margin-top: 50px;
  width: 70%;
}

.link {
  color: blue;
  cursor: pointer;
}

.article_text {
  padding: .5rem;
}

.listing_item {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.listing_item_img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 20px;
}

.listing_item_content {
  display: flex;
  flex-direction: column;
}

.listing_item_title {
  font-size: 16px;
  font-weight: 500;
}

.filters {
  position: sticky;
  top: 15px;
  height: fit-content;
  width: 35%;
}

.profile_santa_card_image {
  object-fit: cover;
  height: 200px;
}

.profile_santa_card_item {
  text-align: center;
  cursor: pointer;
  height: 500px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile_santa_card_add {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile_santa_card_add:hover {
  background-color: #1677ff;
  color: #fff;
}

.profile {
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.profile_santa_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  justify-items: center;
}

.profile_content {
  padding: 14px;
  border-radius: 10px;
}

.profile_header {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  border-radius: 0 8px 0 0;
}

.sider {
  border-radius: 8px 0 0 8px;
}

.menu {
  border-radius: 8px 0 0 0;
}

.profile_content_layout {
  border-radius: 0 0 8px 0;

}

.profile_subtitle {
  font-family: 'Pacifico', cursive;
  font-size: 2rem;
  font-weight: 700;
  margin: auto;
}

.spinner {
  display: flex;
  justify-content: center;
}

.filter_block {
  border: 1px dashed #1677ff;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  max-height: 260px;
  overflow-y: auto;
}

@media (max-width: 1440px) {
  .profile_santa_list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1024px) {
  .profile_santa_list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .listing_item {
    flex-direction: column;
  }

  .listing_item_img {
    width: 100%;
  }

  .listing_item_content {
    margin: 10px;
  }

  .listing_item_button {
    width: 100%;
  }

  .profile_santa_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .section {
    flex-direction: column;
    margin-top: 50px;
  }

  .main_image {
    width: 100%;
  }

  .card {
    width: 100%;
  }

  .main_first_block {
    flex-direction: column-reverse;
  }

  .main_block_content {
    flex-direction: column-reverse;
  }

  .search {
    margin-top: 0;
    margin-bottom: 30px;
    width: 100%;
  }

  .listing_top {
    flex-direction: column-reverse;
  }

  .block {
    width: 100%;
  }

  .profile_santa_list {
    grid-template-columns: 1fr;
  }

  .card-container {
    flex-direction: column;
  }

  .container {
    padding: 0;
  }

  .title {
    font-size: 1.5rem;
  }

  .h3 {
    font-size: 1rem;
  }
}